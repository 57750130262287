#informes-container{
    text-align: left;
}

#informes-label{
    padding-bottom: 10px;
    font-size: 1.1em;
    display: block;
}

.message{
    padding: 7px 20px 7px 20px;
    margin-bottom: 10px;
}

.informe-item:hover{
    background-color: aliceblue;
    cursor: pointer;
}