.table {
    width: 100%;
    border-collapse: collapse;
    min-width: 500px;
  }
  
  .table th, .table td {
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    border-bottom: 2px;
    border-color: #a8a8a7;
    border-bottom-style: solid;
    font-weight: bold;
  }
  
  .table td { 
    min-width: 80px;/* Ajusta el valor según sea necesario */
  }
  .table td.nombre{
    width: 700px;
  }