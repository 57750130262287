.context{
    margin-left: calc(40px + 1rem);
}
.manual{
    margin-left: calc(40px + 1rem);
}
.nav-link{
    font-weight: 600;
    font-size: 1.1em;
    color: #A8A8A7;
}
.sticky-card {
    position: -webkit-sticky; /* Para soporte en navegadores Webkit */
    position: sticky;
    top: 0; /* Ajusta según la posición que desees */
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos si es necesario */
}

.nav-link:hover {
    text-decoration: none;
    background-color: #097275;
    color: white;
    border-radius: 5px;
    transition: transform 0.8s ease-out;
}
.menu{
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.945);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(10.8px);
-webkit-backdrop-filter: blur(10.8px);
border: 1px solid rgba(255, 255, 255, 0.7);
position: fixed;
max-width: 400px;
max-height: 450px;
top: calc(200px + 1rem);
z-index: -0;


}
.card{
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.945);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(10.8px);
-webkit-backdrop-filter: blur(10.8px);
border: 1px solid rgba(255, 255, 255, 0.7);

}

.navegacion{
    width: 100%;
}

.centro{
    min-width: 280px;;
    display: flex;
    text-align: start;
}

.card-header{
    background: rgba(255, 255, 255, 0.27);
}

.card-header img{
    margin-bottom: 10px;
    margin-top: 0;
    padding-top: 0;
}
.selected {
    text-decoration: none;
    background-color: #097275;
    color: white;
    border-radius: 5px;
}
.card-titulo{
    color: #832347;
    font-weight: bolder;
    font-size: 1.5rem;
    text-align: center;
    font-family: MonropeHeavy, sans-serif;
}
.checkbtn{
    font-size: 30px;
    color: #832347;
    display: none;
}
#check{display: none;}

@media (max-width :800px){
    .checkbtn{
        display: block;
        position: fixed;
        left: 0px;
    }
    .context{
        margin-left: 0;
        width: 100%;
    }
    .manual{
        margin-left: 0;
        width: 100%;
    }
    .centro{
        margin-left:0;
        display: flex;
        align-items: center;
        text-align: start;
        position: fixed;
        width: 100%;
        left: -100%;
        transition: all .5s;
    }
    #check:checked ~ .centro{
        left: 0;
        z-index: 99999;
    }
    #check::backdrop ~ .centro{
        left: -100%;
        z-index: 9999;
    }
    
}
