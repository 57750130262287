a.navbar-brand {
  white-space: normal;
  text-align: center;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@font-face {
  font-family: CleanUX;
  src: url("../tipografias/AdobeCleanUXRegular.otf") format("opentype");
}
@font-face {
  font-family: FuturaHeavy;
  src: url("../tipografias/FuturaHeavy.otf") format("opentype");
}
@font-face {
  font-family: FuturaSite;
  src: url("../tipografias/Futura.otf") format("opentype");
}

@media (max-width:575px){
  .menu-close{
    display: none;
  }    

  .menu-item, .menu-item-title{
    text-align: left!important;
    font-size: large;
    margin: 0 10px!important;
    padding: 5px 0px!important;
    border-bottom:solid 1px #b6b6b6;
    border-radius: 10px;
  }

  .arrow-element{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 20px!important;
  }

  .menu-item-title{
    background-color:#3a0c1d;
    text-align: center!important;
  }

  .menu-item:hover{
    background-color: #97254e;
  }
  .menu-item a{
   display: block;

  }
  
  .menu-item{
    width:100%;
    padding: 5px 0px 5px 20px!important;
    padding-left: 20px;
  }
  .nav-height{
      height:250px;
      
  }
    .nav-height-0{
        height:0;
    }

    #main-menu {
        display: none;
    }
  /*======END MOBILE=======*/
  .d-block{
      padding-bottom:25px;
  }
}
.szh-menu{
  background-color:#57152d ;
  color:white !important;
}

.szh-menu a{
  font-weight: 500;
  color: white !important;
}

.szh-menu-button {
  font-weight: 500;
  color: #57152d !important;
}

.szh-menu-button:focus {
  border: none;
  box-shadow: none;
}

.szh-menu__arrow {
  background-color:#57152d;
}
.szh-menu-button:hover {
  font-weight: 500;
  
  text-shadow: 0 0 20px #57152d;
}
.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot{
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  margin-top: 35px;
}
.owl-nav button.owl-prev, .owl-nav button.owl-next{
width:45px;
margin-top: 35px;
height: 400px;
}

.szh-menu a:hover{
  color:#000 !important;
}
.toggle-button{
  border: none;
  color: white;
}
.toggle-button i{
  color:white;
}
header{
  background-color: #57152d;
  height: 48px;
}

.navbar{
  height: 48px;
  border-bottom: none!important;
  z-index: 61;
}
.navbar-brand img{
  padding-left: 2rem;
}
.logo-Navbar{
  height: 80px;
}

@media (max-width:999px) {
.logo-Navbar{
height: 40px;
}
}
.menu-wrapper{
  position: relative;
  z-index: 60;
}

.menu-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-image: url(../images/top-bg-rombos.png);
  
  background-size: 101%;
  background-repeat: no-repeat;
  height:90px;
  color: black;
  
  top: 0;
  z-index:3000;
  
}

.menu-container {
    font-size: 1.3rem;
    display: flex;
    align-items: center;
}.menu-container:nth-child():hover{
  color:#57152d !important;
}

.menu-container a{
  color:#000;
}
.menu-container a:hover{
  color:#832347;
}


.sections{
  font-size: 1.2rem;
  font-weight: 600;
  padding-right: 5%;
}
.sections span{
  padding: 0 15px 0 0
}

.menu-item-title{
  color:white;
  font-weight: bold;
  margin:15px 0;
  text-align: end;
} 

.menu-item a{
  color:white;

}

.menu-item{
  margin: 8px 0;
  text-align: end;
}

/*.nav-link-menu:hover{
  border-radius: 10px;
  background-color: #97254e;
}*/

.nav-link-menu{
  border-radius: 10px;  
}

.nav-link-menu-active {
  border-radius: 10px;
  background-color: #97254e;
}

.menu-close{
  border:0 solid #57152d;
  background-color: transparent;
  color: whitesmoke;
  width: 55px;
}

.menu-close:hover{
  background-color: #57152d63;  
}

.cerrar-menu {
    color: white;
        font-size: 1.5rem ;
        display: flex ;
        align-items: center;
        cursor: pointer ;
        justify-content: flex-end;
}
