.event-publicacion1 {
    background-color: #47b9b8 !important;
    border-color: #47b9b8 !important;
    color: #000000 !important;
}
.event-publicacion2 {
    background-color: #e94d56 !important;
    border-color: #e94d56 !important;
    color: #000000 !important;
}
.event-publicacion3 {
    background-color: #a13089 !important;
    border-color: #a13089 !important;
    color: #000000 !important;
}
.event-publicacion4 {
    background-color: #f39015 !important;
    border-color: #f39015 !important;
    color: #000000 !important;
}
.fc-toolbar-title {
    text-transform: uppercase;
    font-weight: 900;
}
.fc-scrollgrid-sync-inner a{
    color: #000000;
}