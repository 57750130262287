@font-face {
    font-family: CleanUX;
    src: url("../tipografias/AdobeCleanUXRegular.otf") format("opentype");
}
@font-face {
    font-family: FuturaHeavy;
    src: url("../tipografias/FuturaHeavy.otf") format("opentype");
}
@font-face {
    font-family: FuturaSite;
    src: url("../tipografias/Futura.otf") format("opentype");
}

.card-ii{
    background-color: #faf2e8;
    margin: 5px;
    padding-top: 15px;
}
.card-ii-green{
    background-color: #bfe4b7;
    margin: 5px;
    padding-top: 15px;
}

.image-ii{
    margin:0 auto;
}




.ic-main-container{
    background-color:#fffa9699;
    padding:35px 130px;
}



.ic-tipo-auditoria{
    display: flex;
    align-items: center;
}

.icono-tipo-auditoria{
    background-color: #aaaaaa;
    width: 80px;
    border-radius: 50%;
    padding: 15px 15px 15px 15px;
    margin-left: -20px;
}

.titulo-tipo-auditoria{
    background-color: #832347;
    color: white;
    font-size: 1.4rem;
    line-height: 2;
    width: 100%;
    padding-left: 20px;
}

.ic-top{
    display: flex;
}
.tipo_i_ente{
    width: 100%;
}
.ic-resumen{
    display: flex;
    overflow-wrap: break-word;
    justify-content: center;
    align-items: center;
    font-family: 'FuturaHeavy';
    line-height: 1.1;
    padding:30px 0;
    margin-right: 20px;
    min-width: 50%;
    background-color: #832347;
    color: white;
    font-size: 3.5rem;
    text-align: center;
    border-radius: 0px 0px 80px;
}
.ic-nombre-ente{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 600;
    font-family: 'FuturaHeavy';
    margin-top: 10px;
    line-height: 1.2;
}
.ic-porque{
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.icono-porque{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px 0px 0px 30px;
    background-color: #832347;
    padding: 20px;
}
.ic-porque-container{
    display: flex;
    background-color: white;
    width: 100%;
    border-radius: 30px;
}
.ic-rectangle-container{
    display: flex;
    background-color: white;   
    border-radius: 30px;
    margin: 0 10px 20px;
    width: 550px;
}

.ic-rectangles{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.seccion-titulo{
    text-align: center;
    padding: 10px 10px 10px 10px;
    font-size: 1.8rem;
    font-family: 'FuturaHeavy';
    color: #832347;
    
}

.text-md{
    font-size: 1.4rem;
}
.text-lg{
    font-size: 1.8rem;
}
.text-xl{
    font-size: 2.5rem;
}
.blue{
    color:rgb(21, 61, 134);
}
.informe-digital{
    left: 0;
    width: 100%;
    border: solid 2px #832347;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.informe-digital-header{
    margin-top: 20px;
background-color: #832347;
border-radius: 15px 15px 0 0;
width: 100%;

color: white;
text-align: center;
font-size: 1rem;
line-height: 2;
}


.informe-digital-files{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    
    

}
.rectangle-cantidad{
    text-align: center;
    font-family: 'FuturaHeavy';
    color: #832347;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.flex-center{
    display: flex;
    justify-content: center;
}
.ic-filters{
    
    max-width: 400px;
    
}

.ic-hr{
    color: rgb(227, 198, 39);
    height: 3px !important;
    opacity: 1 !important;
    margin: 0.2rem 0px 0px;
}

.ic-hr-2{
    color: #832347;
    height: 3px !important;
    opacity: 1 !important;
    margin: 0.2rem 0px 0px;
}
.ic-select{
    padding: 10px;
    width: 400px;

}

.seccion-texto{
padding: 12px;
white-space: pre-wrap;
}

.ic-seccion-big{
    margin-top: 20px;
    background-color: white;
    border-radius: 30px;
}

.heading-seccion-big{
    display: flex;
    align-items: center;

}
.icon-seccion-big{
    text-align: center;
    padding-top: 5px;
    background-color: #832347;
    width: 80px;
    height: 60px;
    border-radius: 30px 0 0 0;
}

.texto-seccion-big{
    padding: 20px 50px;
    white-space: pre-line;
}

.ic-rectangles{
    margin-top: 20px;
    display: flex;
    align-items: center;
}

@media (max-width:453px){
  .icon-seccion-big{
    height: 95px;
  }  
  .icon-seccion-big img{
    margin-top: 20px;
  }
}

@media (max-width:991px){
    .ic-main-container{
        padding: 20px 20px;
    }    
    .ic-rectangle-container{
        width: 100%;
        margin: 0 0 20px;
    }

    .ic-select{
        padding: 10px;
        width: 350px;    
    }

    .ic-top{
        flex-direction: column;
    }
    .icono-tipo-auditoria{
        width: 40px;
        height: 40px;
        padding: 6px 6px;
    }
    .ic-tipo-auditoria{
        margin-top: 10px;
    }
    .icono-tipo-auditoria img{
        width: 30px;
        height: 30px;
    }
    .ic-resumen{
        font-size: 2rem;
        border-radius: 0;
        width: 100%;
    }
    .titulo-tipo-auditoria{ 
        text-align: center;     
        font-size: 1.2rem;
    }
    .informe-digital{
        width: 100%;
    }

    .texto-seccion-big{
        padding: 10px 20px;
        
    }

    .icono-porque {
        padding: 5px;
    }

    .seccion-titulo{
        padding: 5px;
        font-size: 1.3rem;
    }

    .icono-porque img{
        width: 60px;
    }
    .rectangle-cantidad{
        flex-direction: column;
    }



}