.bodyApp {
  color: #383838;
}

.App {
  text-align: center;
}

.paragraph {
  text-align: justify;
  font-size: 1.3em;
  text-indent: 1em;
  font-weight: 400;
}
.capital {
  font-size: 1.4em;
  font-weight: 600;
}
.App-header {
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: black;
  justify-content: space-evenly;
  align-items: center;
}
.title {
  font-size: 1.8em;
  font-weight: 500;
}
.cardx {
  border-radius: 6px;
  background-color: rgb(219, 207, 101);
  padding: 1vw;
  text-align: center;
  font-size: 18px;
  justify-self: flex-end;
  max-width: 400px;
}
.fraccion-titulo {
  font-size: 2.4em;
  font-weight: 500;
}

.v-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}
.formatos-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
}

.buttons-bar {
  display: flex;
  /*justify-content: space-evenly;*/
  /*flex-wrap:wrap;*/
  overflow-x: auto;
}
.buttons-fotmato {
}
.h-center {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* height: 100%;*/
}
.full-width {
  width: 100%;
}

.centerx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.pd-10 {
  float: right;
}

.paragraph-home {
  display: inline;
  margin: 10px;
  text-align: justify;
}

.pull-right {
  float: right;
}
.App-link {
  color: #61dafb;
}
.contenedor {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.header-container {
  display: flex;
  justify-content: space-evenly;
}
.descripcion {
  color: whitesmoke;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.square-name {
  font-size: 2.5rem;
  padding: 12px;
}
.square-nameOtras {
  font-size: 1.5rem;
  padding: 12px;
}

.double {
    display: flex;
    align-items: center;
}
.radios-container {
  width: 100%;
  background-color: rgb(245, 245, 245);
  border-radius: 8px 8px 0 0;
  display: flex;
}
.my-tabs > label > input[type=radio] {
    display: none;
}

.text-bold {
  font-weight: 600;
}

.my-tabs {
  color: whitesmoke;
  background-color: #777777;
  border-radius: 10px 10px 0 0;
  margin-left: 6px;
  padding: 13px;
  cursor: pointer;
  max-width:120px;
}
.my-tab-off {
  color: whitesmoke;
  background-color: #777777;
  border-radius: 10px 10px 0 0;
  margin-left: 6px;
  padding: 0px;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.my-tab-on {
  color: whitesmoke;
  background-color: #383838;
  padding: 13px;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
}

.fecha-upd {
  font-size: 0.6vw;
}

.square {
  font-weight: 500;
  text-align: center;
  width: 12vw;
  height: 12vw;
  margin: 5px;
  border-radius: 5px;
  color: whitesmoke;
  cursor: pointer;
  min-width: 150px;
  background: #8f0222; /* Old browsers */
  background: -moz-linear-gradient(
    45deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f0222', endColorstr='#6d0019',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.part-1 {
  border-color: whitesmoke;
  border-right-style: dashed;
  border-right-width: 3px;
}
.parts {
  padding: 16px;
  height: -webkit-fill-available;
  width: 12vw;
}
.no-underline {
  text-decoration: none !important;
  color: whitesmoke;
}

.rect-70:hover,
.square:hover,
.parts:hover {
  -webkit-box-shadow: inset 0px 0px 45px -7px rgba(48, 48, 48, 1);
  -moz-box-shadow: inset 0px 0px 45px -7px rgba(48, 48, 48, 1);
  box-shadow: inset 0px 0px 45px -7px rgba(48, 48, 48, 1);
}

.rectangle {
  font-weight: 500;
  text-align: center;
  height: 4rem;
  margin: 5px;
  min-width: 200px;
  border-radius: 5px;
  color: whitesmoke;
  cursor: pointer;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#8f0222+47,a90329+53,6d0019+100 */
  background: #8f0222; /* Old browsers */
  background: -moz-linear-gradient(
    25deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    25deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    25deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f0222', endColorstr='#6d0019',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.rectangleOtras {
  font-weight: 500;
  text-align: center;
  height: 5rem;
  margin: 5px;
  min-width: 200px;
  max-width: 400px;
  border-radius: 5px;
  color: whitesmoke;
  cursor: pointer;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#8f0222+47,a90329+53,6d0019+100 */
  background: #8f0222; /* Old browsers */
  background: -moz-linear-gradient(
    25deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    25deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    25deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f0222', endColorstr='#6d0019',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}
.bg-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#8f0222+47,a90329+53,6d0019+100 */
  background: #8f0222; /* Old browsers */
  background: -moz-linear-gradient(
    45deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    #8f0222 47%,
    #a90329 53%,
    #6d0019 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f0222', endColorstr='#6d0019',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.big-number {
  font-size: 4rem;
}

.content {
  font-size: 2em;
  padding-top: 20%;
}

.image-pnt {
  max-width: inherit !important;
}

/*===============Tooltip====================*/
.my-tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}

.my-tooltip .bottom {
  min-width: 200px;
  top: 128px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 20px;
  color: #444444;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
}

.my-tooltip:hover .bottom {
  display: block;
}

.my-tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.my-tooltip .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

#downloadContainer {
  margin-left: auto;
}
/*===========//Hover===============*/
.ReactTable .rt-tr .action {
  transition: all 0.2s ease;
  text-align: center;
  color: #dfdfdf;
  transform: scale(0);
}

.ReactTable .rt-tr:hover .action {
  transform: scale(1.3);
}

.ReactTable .rt-tr:hover .rt-td {
  background: #ebebeb;
}

.btn-page {
  background-color: #ebebeb;
  color: black;
  border: none;
  padding: 0.55rem;
  font-size: 1.19rem;
  border-radius: 3px;
}

.btn-page:hover {
  transform: scaleX(1.1) scaleY(1.1);
  background-color: #d1cdcd;
}

.btn-page::after {
  opacity: 0;
}

.react-pdf__Page {
  width: 100%;
}

.title-extracto {
  font-size: 1.5em;
}
/*===========//Tooltip===============*/

/* [type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: rgb(133, 12, 54);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
} */

/*radiobuttons*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
