@media (min-width: 1281px) {
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */



  @media (min-width: 250px) and (max-width: 767px) {
    .title{
         font-size:1.4rem
                  
    }
    #downloadContainer{
        font-size:.6rem;
    }
    .my-tabs{
        font-size:.8rem
    }
    .fraccion-titulo{
        font-size:1.8rem;
    }
    .capital {
      font-size: 1.2rem;
    }
    .h-center {
      flex-direction: column;
    }
    .square {
      min-width: 140px;
      min-height: 80px;
    }
   .paragraph{
       font-size:1rem;
   }
    .square {
      min-width: 140px;
      min-height: 80px;
    }
    .cardx {
      font-size: 13px;
      margin-top: 10px;
    }
    .big-number {
      font-size: 28px;
    }
    .double {
      display: block;
    }
    .descripcion {
      font-size: .8rem;
    }
    .fecha-upd {
      display: none;
    }
    .my-tooltip {
      display: none;
    }
    .square-name {
      font-size: 1.8em;
    }

    
   
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  /*@media (min-width: 320px) and (max-width: 480px) {
    h1 {
      font-size: 24px;
    }
    .capital {
      font-size: 24px;
    }
    .h-center {
      flex-direction: column;
    }
    .square {
      min-width: 125px;
      min-height: 80px;
    }
    .cardx {
      font-size: 13px;
      margin-top: 10px;
    }
    .big-number {
      font-size: 28px;
    }
    .double {
      display: block;
    }
    .descripcion {
      font-size: .8rem;
    }
    .fecha-upd {
      display: none;
    }
    .my-tooltip {
      display: none;
    }
    .square-name {
      font-size: 1.8em;
    }
  }*/
}
