#entes-contaienr{
    text-align: left;
}

#entes-label{
    padding-bottom: 10px;
    font-size: 1.1em;
    display: block;
}

.busqueda{
    margin-bottom: 10px;
    background-color: hsl(0,0%,98%);
    height: 36px;
}

.busqueda:focus{
    border-radius: 4px;
    box-shadow: 0 0 0 1px #2684FF;
    border: 1px solid #2684FF;    
}

.ente-item:hover{
    background-color: aliceblue;
    cursor: pointer;
}