.sgmt-container{
    margin-top:25px;
    display: flex;
    flex-direction: column;
}
tr{
    border-bottom: 1px solid #ddd;
    line-height: 2.5em;
}
.sgmt-row{
    background-color: rgb(49, 49, 49);
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px ;
}
.rt-tr-group :hover{
    background-color: whitesmoke;
}

.rt-tbody{
    text-align: center;
}
.rt-thead{
    font-weight: bold;;
}
.sgmt-title{
    text-align: center;
    font-size: 1.8em;
    font-weight: bold;
}
.sgmt-row-header{
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px ;
}

.sgmt-ejercicio{
    color: rgb(240, 240, 240);
    font-size: x-large;
    padding: 10px 20px;
}

.sgmt-header{
    padding: 30px 40px;
    width: 33%;
    font-size: x-large;
    margin-top: -20px;
    margin-left: 5px;
    margin-bottom: -20px;
}

.sgmt-header-ejercicio{
    font-size: x-large;
}

.sgmt-numero{
    background-color: whitesmoke;
    border-radius:5px;
    -moz-border-radius:5px;
    -webkit-border-radius:5px;
    -webkit-box-shadow:0px 0px 10px 3px #31313149 ;
    -moz-box-shadow:0px 0px 10px 3px #31313149 ;
    box-shadow:0px 0px 10px 3px #31313149 ;
    padding: 30px 40px;
    width: 33%;
    text-align: center;
    font-size: x-large;
    font-weight: bold;
    margin-top: -20px;
    margin-left: 5px;
    margin-bottom: -20px;
}

.sgmt-numero:hover{
    background-color: rgb(235, 235, 235);
   -webkit-box-shadow:0px 0px 15px 5px #1a1a1a49;
    -moz-box-shadow:0px 0px 15px 5px #1a1a1a49;
    box-shadow:0px 0px 15px 5px #1a1a1a49;
    cursor: pointer;
}