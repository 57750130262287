
strong{
    color: #097275;
}

.centrado{
    display: grid;
    place-items: start;
    align-content: center;
}

.fondo1{
    background-image: url('./../../../images/orientacionCiudadana/background/fondoCiudadana.png');
    background-size: 100% 60%;
    background-repeat: no-repeat;
    z-index: -999;

}
.fondo1 h1{
    display: flex;
    justify-content: center;
    padding-top: 150px;
    color: white;
    height: 800px;
    font-size: 50px;
}
.fondo2{
    background-image: url('./../../../images/orientacionCiudadana/background/fondoCiudadana2.png');
   background-size: 100% 60%;
   background-repeat: no-repeat;
   z-index: -999;
}
.fondo2 h1{
    display: flex;
    justify-content: center;
    padding-top: 170px;
    color: white;
    height: 800px;
    font-size: 50px;
}
.fondo3{
    background-image: url('./../../../images/orientacionCiudadana/background/fondoCiudadana3.png');
   background-size: 100% 60%;
   background-repeat: no-repeat;
   z-index: -999;
}
.fondo3 h1{
    display: flex;
    justify-content: center;
    padding-top: 170px;
    color: white;
    height: 800px;
    font-size: 50px;
}
.fondo4{
    background-image: url('./../../../images/orientacionCiudadana/background/fondoCiudadana4.png');
   background-size: 100% 60%;
   background-repeat: no-repeat;
   z-index: -999;
}
.fondo4 h1{
    display: flex;
    justify-content: center;
    padding-top: 170px;
    color: white;
    height: 800px;
    font-size: 50px;
}
.fondo5{
    background-image: url('./../../../images/orientacionCiudadana/background/fondoCiudadana5.png');
   background-size: 100% 60%;
   background-repeat: no-repeat;
   z-index: -999;
}
.fondo5 h1{
    display: flex;
    justify-content: center;
    padding-top: 170px;
    color: white;
    height: 800px;
    font-size: 50px;
}
.fondo6{
    background-image: url('./../../../images/orientacionCiudadana/background/fondoCiudadana6.png');
   background-size: 100% 60%;
   background-repeat: no-repeat;
   z-index: -999;
}
.fondo6 h1{
    display: flex;
    justify-content: center;
    padding-top: 170px;
    color: white;
    height: 800px;
    font-size: 50px;
}
.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
    
}

.text-container h1 {
    max-width: 300px;
    text-align: center; /* Opcional: para centrar el texto dentro del h1 */
     margin-top: -50px;  /* Mueve el h1 50px hacia arriba */
}


@media(max-width:1050px){
    .fondo2 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 50px;
    }
    .fondo1 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 50px;
    }
    .fondo3 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 50px;
    }
    .fondo4 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 50px;
    }
    .fondo5 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 50px;
    }
    .fondo6 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 50px;
    }
}
@media (max-width :425px){
    .fondo2 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 30px;
    }
    .fondo1 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 30px;
    }
    .fondo3 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 30px;
    }
    .fondo4 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 30px;
    }
    .fondo5 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 30px;
    }
    .fondo6 h1{
        display: flex;
        justify-content: center;
        color: white;
        height: 800px;
        font-size: 30px;
    }
}


.texto p{
    text-align: justify;
}
