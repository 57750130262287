.boton{
    background-color: #832347;
}
.boton:hover{
    transition: .2s ease;
    box-shadow: 0px 0px 10px 0px #832347;
    background-color: #832347;
    border-width: 0;
    border-color: transparent;
}
.boton:active{
    background-color: #b13260;
}
.form-control{
    background-color: white;
}
.form-control:hover{
    background-color: rgba(128, 128, 128, 0.164);
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}